import styles from './AuthLayout.module.scss';

import * as React from "react"
import { Outlet } from "react-router-dom";

export const AuthLayout = () => {
  return (
    <div className={styles.authWrapper}>
      <div>
        <Outlet />
      </div>
    </div>
  );
};
