import * as React from "react"
import * as ReactDOM from "react-dom/client"
import * as serviceWorker from "./serviceWorker"

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"
import { BrowserRouter as Router, Route, Routes,  } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"

import { AuthLayout } from "./layouts/auth-layout/AuthLayout"
import { MainLayout } from "./layouts/main-layout/MainLayout"
import { HomePage } from "./pages/home-page/HomePage"

import theme from './theme';
import { LoginPage } from "./pages/login-page/LoginPage"

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="home" element={<HomePage />} />
          </Route>
          <Route path="/" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

