import { Box, Button } from "@chakra-ui/react";
import { signOut } from "firebase/auth";

import { auth } from "../../firebase";

export const HomePage = () => {
  const logout = async () => {
    await signOut(auth);
  }
  return (
    <div>
      HOME PAGE
      <Box>
        <Button colorScheme="blue" variant="solid" size="md" onClick={() => { logout(); }}>Logout</Button>
      </Box>
    </div>
  );
};
