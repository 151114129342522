import * as React from "react"
import { Input } from "@chakra-ui/input";
import { Box } from "@chakra-ui/layout";
import { VStack, Text, Button, HStack, FormControl, FormErrorMessage, useToast } from "@chakra-ui/react";
import { FaFacebook, FaGoogle, FaMicrosoft, FaTwitter } from "react-icons/fa";

import { FirebaseError } from "firebase/app";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup, TwitterAuthProvider } from "firebase/auth";

import { Logo } from "../../ui/logo/Logo";

import './LoginPage.scss';
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const [edited, setEdited] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const isEmailError = edited && email === '';
  const isPasswordError = edited && password === '';

  const toast = useToast();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!isEmailError && !isPasswordError) {
      try {
        const loginResult = await signInWithEmailAndPassword(auth, email, password);
  
        if (loginResult) {
          const displayName = loginResult.user.displayName || loginResult.user.email;
          toast({
            title: 'Logged in',
            description: `Welcome back ${displayName}.`,
            status: 'success',
          });
  
          navigate('/home');
        }
      } catch(e) {
        const error = e as FirebaseError;
        console.error(error.code)
        if (error.code === 'auth/user-not-found') {
          toast({
            title: 'Authentication Failed',
            description: 'User with given email was not found.',
            status: 'error',
          })
        }
        if (error.code === 'auth/invalid-email') {
          toast({
            title: 'Authentication Failed',
            description: 'Given email address is invalid',
            status: 'error',
          })
        }
      }
    } else {
      toast({
        title: 'Authentication Failed',
        description: 'Email address and/or password missing.',
        status: 'error',
      })
    }
  }

  const handleSocialLogin = async (platform: 'google' | 'facebook' | 'twitter' | 'microsoft') => {
    let loginResult = null;
    try {
      switch(platform) {
        case 'google':
          loginResult = await signInWithPopup(auth, new GoogleAuthProvider());
          break;
        case 'facebook':
          loginResult = await signInWithPopup(auth, new FacebookAuthProvider());
          break;
        case 'twitter':
          loginResult = await signInWithPopup(auth, new TwitterAuthProvider());
          break;
        case 'microsoft':
          loginResult = await signInWithPopup(auth, new OAuthProvider('microsoft.com'));
          break;
      }

      if (loginResult) {
        const displayName = loginResult.user.displayName || loginResult.user.email;
        toast({
          title: 'Logged in',
          description: `Welcome back ${displayName}.`,
          status: 'success',
        });

        navigate('/home');
      }
    } catch(e) {
      const error = e as FirebaseError;
      toast({
        title: error.code,
        description: error.message,
        status: 'error',
      })
    }
  }

  return (
    <Box className="loginWrapper" bg='white' w={['80vw', '60vw', '30vw']} boxShadow="xl">
      <div className="loginHeader">
        <Logo />
      </div>
      <div className="loginContent">
        <Text paddingStart={4} fontSize={16}>Login</Text>
        <Box w='100%' p={4}>
          <VStack spacing={4}>
            <FormControl isInvalid={isEmailError}>
              <Input onChange={(event) => { setEdited(true); setEmail(event.target.value) }} placeholder='Email' type="email" />
              <FormErrorMessage>Email is required.</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={isPasswordError}>
              <Input onChange={(event) => { setEdited(true); setPassword(event.target.value) }} placeholder='Password' type="password" />
              <FormErrorMessage>Password is required.</FormErrorMessage>
            </FormControl>
            <HStack spacing={4} alignContent="space-between">
              <Button colorScheme="blue" variant="text" size="sm">Forgot password?</Button>
              <Button colorScheme="blue" variant="solid" size="md" onClick={() => { handleLogin(); }}>Login</Button>
            </HStack>
          </VStack>
        </Box>
        <Box p={4}>
          <Text fontSize={14} color="gray.500">Or use social login</Text>
          <div className="socialLogins">
            <div className="socialLogin socialLoginGoogle" onClick={() => { handleSocialLogin('google'); }}>
              <FaGoogle size={24} />
            </div>
            <div className="socialLogin socialLoginFacebook" onClick={() => { handleSocialLogin('facebook'); }}>
              <FaFacebook size={24} />
            </div>
            <div className="socialLogin socialLoginTwitter" onClick={() => { handleSocialLogin('twitter'); }}>
              <FaTwitter size={24} />
            </div>
            <div className="socialLogin socialLoginMicrosoft" onClick={() => { handleSocialLogin('microsoft'); }}>
              <FaMicrosoft size={24} />
            </div>
          </div>
        </Box>
      </div>
      <div className="loginFooter">
        <Box p={4}>
          <Text fontSize={12} color="gray.700">&copy; COPYRIGHT 2022</Text>
        </Box>
      </div>
    </Box>
  );
};
