// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from '@chakra-ui/react'
// import { StyleFunctionProps } from '@chakra-ui/theme-tools'

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: '\'Ubuntu\', sans-serif',
      }
    }
  },
  colors: {
    gray: {
      50: "#F0F2F4",
      100: "#D6DAE1",
      200: "#BBC3CE",
      300: "#A0ABBA",
      400: "#8693A7",
      500: "#6B7C94",
      600: "#566376",
      700: "#404A59",
      800: "#2B323B",
      900: "#15191E"
    },
    red: {
      50: "#FBE9E9",
      100: "#F5C2C2",
      200: "#EE9B9B",
      300: "#E77474",
      400: "#E14C4C",
      500: "#DA2525",
      600: "#AE1E1E",
      700: "#831616",
      800: "#570F0F",
      900: "#2C0707"
    },
    orange: {
      50: "#FDF0E7",
      100: "#FAD5BC",
      200: "#F7BA91",
      300: "#F49F67",
      400: "#F1843C",
      500: "#EE6911",
      600: "#BF540D",
      700: "#8F3F0A",
      800: "#5F2A07",
      900: "#301503"
    },
    yellow: {
      50: "#FDF6E7",
      100: "#FAE6BC",
      200: "#F7D592",
      300: "#F4C567",
      400: "#F1B53C",
      500: "#EEA411",
      600: "#BE830E",
      700: "#8F630A",
      800: "#5F4207",
      900: "#302103"
    },
    green: {
      50: "#ECF9F2",
      100: "#C9EDDA",
      200: "#A7E2C2",
      300: "#84D6AA",
      400: "#62CB93",
      500: "#3FC07B",
      600: "#339962",
      700: "#26734A",
      800: "#194D31",
      900: "#0D2619"
    },
    teal: {
      50: "#EBF9F9",
      100: "#C8EFEE",
      200: "#A4E4E3",
      300: "#81DAD8",
      400: "#5ECFCD",
      500: "#3AC5C1",
      600: "#2F9D9B",
      700: "#237674",
      800: "#174F4D",
      900: "#0C2727"
    },
    cyan: {
      50: "#E6FBFF",
      100: "#B8F3FE",
      200: "#8BEBFE",
      300: "#5EE3FD",
      400: "#31DBFC",
      500: "#03D4FC",
      600: "#03A9C9",
      700: "#027F97",
      800: "#015565",
      900: "#012A32"
    },
    blue: {
      50: "#EBF3FA",
      100: "#C6DCF1",
      200: "#A1C6E8",
      300: "#7CB0DF",
      400: "#579AD6",
      500: "#3284CD",
      600: "#2869A4",
      700: "#1E4F7B",
      800: "#143552",
      900: "#0A1A29"
    },
    purple: {
      50: "#F0EDF7",
      100: "#D6CDEA",
      200: "#BBADDC",
      300: "#A18DCE",
      400: "#866CC0",
      500: "#6B4CB3",
      600: "#563D8F",
      700: "#402E6B",
      800: "#2B1F47",
      900: "#150F24"
    },
    pink: {
      50: "#FEE7F1",
      100: "#FBBBD7",
      200: "#F990BD",
      300: "#F664A4",
      400: "#F4398A",
      500: "#F20D71",
      600: "#C10B5A",
      700: "#910844",
      800: "#61052D",
      900: "#300317"
    }
  },
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'bold', // Normally, it is "semibold"
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      // 3. We can add a new visual variant
      variants: {
        'with-shadow': {
          bg: 'red.400',
          boxShadow: '0 0 2px 2px #efdfde',
        },
        // 4. We can override existing variants
        // solid: (props: StyleFunctionProps) => ({
        //   bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
        // }),
        // 5. We can add responsive variants
        sm: {
          bg: 'teal.500',
          fontSize: 'md',
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        size: 'lg', // default is md
        variant: 'sm', // default is solid
        colorScheme: 'green', // default is gray
      },
    },
  },
})

export default theme