import { useState } from "react"
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";

import styles from './MainLayout.module.scss';

export const MainLayout = () => {
  const [showRef, setShowRef] = useState(false);

  let navigate = useNavigate();
  
  auth.onAuthStateChanged((user) => {
    console.log({user})
    if (user === null) {
      navigate("/login", { replace: true });
    } else {
      setShowRef(true);
    }
  });

  return (
    <div className={showRef ? styles.show : styles.hidden}>
      <Outlet />
    </div>
  );
};
