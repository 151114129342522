import { Box } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import { CgFormatSeparator } from 'react-icons/cg';

import './Logo.scss';

export const Logo = () => {

  return (
    <Box className="logo">
      <Text><CgFormatSeparator /><span>Node</span>Forms</Text>
    </Box>
  );
};
